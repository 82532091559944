@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&family=VT323&display=swap');

body {
  margin: 20px;
  font-family: 'Poppins';
  color: #333;
}
a {
  color: rgb(3, 196, 154);
}
img {
  max-width: 100%;
  width : 300px;

}
nav {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 40px;
}
nav h1 {
  margin-right: auto;
}
.content {
  max-width: 960px;
  margin: 0 auto;
}
.product {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.offers, .products {
  display: flex;
  gap: 20px;
}